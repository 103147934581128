import { useCustomerCartStore } from "@/views/pos/stores/customer";
import { ListItem } from "@/views/pos/composables/useProductSearch.d"

export default function useCustomer() {

  const customerStore = useCustomerCartStore();

  const searchCustomer = async (query: string) => {
    const value = query.trim();
    if(value == '') {
      return;
    }
    await customerStore.searchCustomer(value);

    const list = customerStore.searchList.map((item): ListItem => {
    	return { value: item.id, label: JSON.parse(item.id) }
    })

    return list;
  }

  const searchResults = () => {
    const list = customerStore.searchList.map((item): ListItem => {
      return { value: item.id, label: item.customer_label }
    })

    return list;
  }

  return {
    searchCustomer,
    searchResults
  }
}