import { useCartStore } from "@/views/pos/stores/cart";
import { usePOSStore } from "@/views/pos/stores/pos"
import { useContentStore } from "@/stores/content";
import { useI18n } from "vue-i18n";
import { useCustomerCartStore } from "../stores/customer";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/auth";

export default function useSession() {
  const cartStore = useCartStore();
  const posStore = usePOSStore();
  const customerStore = useCustomerCartStore();
  const { t } = useI18n();
  const router = useRouter();
  const contentStore = useContentStore();
  const authStore = useAuthStore();

  const initSession = async (posId: number, saleId: string) => {
    cartStore.isLoading = true;
    await posStore.getPOS(posId);

    if (!posStore.currentSessionId || posStore.currentCashierUserId !== authStore.userId) {
      router.push({ path: `/pos/${posId}/checkout` })
      return;
    }

    // Reset
    cartStore.isCompleted = false;

    await cartStore.initCartSession(saleId);

    if (cartStore.getSale) {
      contentStore.setBreadcrumbTitle(t('titles.POS') + ' (' + posStore.currentPosName + '): ' + cartStore.saleId);
      // if (customerStore.currentCustomer) {
      //   posStore.createPaymentMethods();
      // }

      cartStore.isLoading = false;
    }
  }

  return {
    initSession
  }
}