import { useCartStore } from "@/views/pos/stores/cart";

export default function useTabel() {
  const cartStore = useCartStore();
  let timeoutHandler;

  const updateQuantity = (quantity: string, lineId: number) => {
    clearTimeout(timeoutHandler);

    timeoutHandler = setTimeout(() => {
      cartStore.itemQuantity(quantity, lineId)
    }, 500)
  }

  return {
    updateQuantity
  }
}